.modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 6;
	background: rgba(0,0,0, 0.85);
	transform: scale(0);
	opacity: 0;
	transition: opacity 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);

	&.visible {
		transform: scale(1);
		opacity: 1;
	}

	.newsletter {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.inner {
		background: $base-navy;
		padding: 40px 50px 50px 50px;
		border-radius: 4px;
		text-align: center;
		max-width: 550px;
		box-shadow: 0 0 40px 0 #222;

		img {
			margin-bottom: 5px;
		}

		h2 {
			font-size: 30px;
			color: #fff;
			margin-bottom: 0;
		}

		p {
			color: #fff;
			margin-bottom: 20px;
		}

		form {
			display: flex;
			justify-content: space-between;
			align-items: center;

			input:first-child {
				flex: 1;
				border: none;
				padding: 13px;
				border-radius: 4px 0 0 4px;
			}

			input:last-child {
				border-radius: 0 4px 4px 0;
				border: none;
				padding-bottom: 15px;
				cursor: pointer;
			}
		}
	}

	.close-icon {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;

		svg {
			pointer-events: none;
			fill: #fff;
			width: 32px;
			height: 32px;
		}
	}
}
