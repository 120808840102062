.post-card {
	background: #fff;
	border: 1px solid rgba(51,71,89,0.12);
	box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
	border-radius: 4px;
	padding: 20px;

	&.hidden {
		display: none;
	}

	.featured-image {
		margin-bottom: 20px;

		img {
			display: block;
			border-radius: 4px;
			object-fit: cover;
			object-position: top center;
			width: 100%;
			height: 160px;
		}
	}

	.content {
		.meta {
			color: $accent-yellow;
			font-weight: 600;
			font-size: 13px;
			margin-bottom: 12px;

			a {
				color: $accent-yellow;
				font-size: 13px;

				&::before {
					background-color: $accent-yellow;
				}
			}
		}

		h3 {
			margin: 0;

			a {
				font-size: 19px;
				line-height: 30px;
				color: $base-navy;
				font-weight: 500;
			}
		}
	}
}
