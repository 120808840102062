.showcase {
	.artifact-grid-vertical {
		top: 150px;
		left: -20px;

		@include phone {
			transform: rotate(90deg);
			left: -120px;
			top: 210px;
			z-index: -1;
		}
	}

	.categories {
		margin: 40px 0 50px 0;

		@include phone {
			margin: 15px 0 35px 0;
			right: 0;
			left: 0;
			width: 100%;
		}
	}

	.showcase-list {
		margin: 0 auto;
		padding: 0 25px;
		position: relative;
		z-index: 2;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 25px;
		grid-row-gap: 25px;

		@include full-width;

		@include desktop-wide {
			grid-template-columns: repeat(4, 1fr);
		}

		@include desktop-extended {
			grid-template-columns: repeat(5, 1fr);
		}

		@include tablet {
			grid-template-columns: repeat(2, 1fr);
		}

		@include phone {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}
