.homepage {
	.hero {
		background: $base-navy;
		position: relative;

		@include full-width;

		@include phone {
			overflow: hidden;
		}

		> .container {
			@include phone {
				padding: 0 20px;
			}
		}

		.content {
			$content-spacing: 25px;

			position: relative;
			z-index: 2;
			max-width: 790px;
			margin: 0 auto;
			text-align: center;
			padding-top: 80px;

			@include phone {
				padding-top: 50px;
			}

			h1 {
				color: #fff;
				font-size: 52px;
				margin-bottom: $content-spacing;

				@include phone {
					font-size: 40px;
				}
			}

			p {
				color: $base-gray;
				font-size: 20px;
				line-height: 37px;
				margin-bottom: $content-spacing;

				@include phone {
					font-size: 18px;
					line-height: 34px;
				}
			}

			.btn {
				margin-bottom: $content-spacing + 10;

				@include phone {
					margin-bottom: $content-spacing;
				}
			}

			.points {
				color: #D2D6DB;
				font-size: 15px;
				padding-bottom: 40px;

				@include phone {
					line-height: 32px;
				}

				span {
					margin: 0 8px;
				}
			}
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			position: relative;
			z-index: 2;

			@include phone {
				display: none;
			}
		}

		.artifact-circle-yellow {
			top: 40px;
			left: -260px;

			@include phone {
				left: -130px;
				top: 70px;
				width: 178px;
				height: 178px;
				background-size: 178px 178px;
			}
		}

		.artifact-circle-blue {
			top: 150px;
			right: -200px;

			@include phone {
				display: none;
			}
		}

		.artifact-grid {
			bottom: 180px;
			right: -40px;

			@include phone {
				bottom: 120px;
				right: -280px;
			}
		}
	}

	hr {
		background: #eaeaea;
		opacity: 1;
		height: 1px;
		border: none;
		width: 100%;
		margin: -1px 0 0 0;
		padding: 0;
		position: relative;
		z-index: 3;
	}

	.reviews {
		padding: 50px 0;

		@include phone {
			padding: 30px 10px;
		}

		&.highlight {
			background: $base-light-navy;
			border-top: 1px solid #F0F0F1;
			border-bottom: 1px solid #F0F0F1;
			z-index: 2;
			position: relative;
			margin-bottom: 100px;

			@include full-width;

			@include phone {
				padding: 30px 20px;
				margin-bottom: 50px;
			}
		}

		.columns {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;

			.column {
				flex: 0 0 30%;

				@include phone {
					flex: 0 0 100%;
					margin-bottom: 40px;
				}

				&:last-child {
					@include phone {
						margin-bottom: 0;
					}
				}

				.stars {
					display: flex;
				}

				p {
					font-size: 16px;
					line-height: 28px;
					color: $base-navy;
					font-weight: 600;
					margin: 15px 0 12px 0;

					@include phone {
						font-size: 15px;
					}
				}

				.reviewer {
					display: flex;
					align-items: center;

					.author {
						font-size: 15px;
						font-weight: 500;
						color: #6f6f6f;
						margin-right: 12px;
					}

					.verified {
						background-color: #EAFAEB;
						background-image: url("/assets/img/icon-review-check.svg");
						background-size: 16px 16px;
						background-repeat: no-repeat;
						background-position: 6px 4px;
						padding: 6px 8px 6px 26px;
						color: $accent-green;
						font-size: 11px;
						font-weight: 700;
						text-transform: uppercase;
						border-radius: 3px;
					}
				}
			}
		}
	}

	.latest-themes {
		background: $base-light-navy;
		padding: 60px 0;
		border-top: 1px solid #f4f4f4;
		border-bottom: 1px solid #f4f4f4;
		margin-bottom: 60px;

		@include full-width;

		@include phone {
			padding: 40px 0 50px 0;
			margin-bottom: 40px;
		}

		.themes {
			padding: 65px 0 50px 0;

			@include phone {
				padding: 35px 0 45px 0;
			}

			.theme-card {
				h2 {
					span {
						font-size: 16px;
					}
				}

				p {
					font-size: 16px;
					margin-bottom: 15px;
				}

				.btn {
					font-size: 13px;
				}
			}

			.slider {
				transition-timing-function: ease-out;
			}

			.tns-ovh {
				overflow: initial !important;

				@include phone {
					overflow: hidden !important;
				}
			}

			.tns-nav {
				height: 5px;
				max-width: 350px;
				margin: 0 auto;
				list-style: none;
				padding: 0;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: space-between;

				button {
					transition: background .4s;
					cursor: pointer;
					border-radius: 4px;
					width: 90%;
					height: 5px;
					border: none;
					background: #eaeaea;
					text-indent: -9999px;
					outline: none;
				}
			}
		}

		.more {
			text-align: center;
			padding-bottom: 10px;

			a {
				text-align: center;
				color: $base-navy;
				font-size: 19px;

				&::before {
					background-color: $base-navy;
				}
			}
		}
	}

	.theme-features {
		position: relative;

		@include full-width;

		.container {
			max-width: 790px;
			margin: 0 auto;

			@include phone {
				padding: 0 20px;
			}
		}

		.section-title {
			margin-bottom: 60px;

			@include phone {
				margin-bottom: 40px;
			}
		}

		ul {
			color: $base-navy;
			margin: 0 0 50px 0;
			padding: 0 0 0 5px;
			list-style: none;
			column-count: 2;

			@include phone {
				column-count: 1;
				margin-bottom: 20px;
			}

			li {
				color: $base-navy;
				font-weight: 500;
				background: url("/assets/img/icon-bullet-triangle.svg") no-repeat 0 7px;
				padding-left: 30px;
				font-size: 15px;
				margin-bottom: 15px;
				line-height: 26px;
			}
		}

		.artifact-circle-blue {
			bottom: -260px;
			left: -130px;
			z-index: 1;
		}
	}

	.ghost-experts {
		background: #FFFFFF;
		border: 1px solid rgba(51,71,89,0.12);
		box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
		border-radius: 4px;
		padding: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 80px;
		flex-wrap: wrap;
		position: relative;
		z-index: 2;

		@include phone {
			margin-bottom: 40px;
		}

		.content {
			@include phone {
				margin-bottom: 20px;
			}

			h2 {
				font-size: 22px;
				font-weight: 600;
				margin-bottom: 10px;
			}

			p {
				margin: 0;
				color: $text-gray;
			}
		}
	}

	.latest-posts {
		position: relative;

		@include full-width;

		.columns {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 30px;

			@include phone {
				padding: 0 20px;
				margin-bottom: 0;
				flex-wrap: wrap;
			}

			.column:first-child {
				flex: 0 0 400px;

				@include phone {
					flex: 0 0 100%;
					margin-bottom: 40px;
					text-align: center;
				}

				h2 {
					margin-bottom: 15px;
				}

				p {
					color: $text-gray;
				}

				a {
					color: $base-navy;
					font-size: 17px;

					&::before {
						background-color: $base-navy;
					}
				}
			}
		}

		.column:last-child {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			article {
				flex: 0 0 48%;

				@include phone {
					flex: 0 0 100%;
					margin-bottom: 25px;
				}

				&:last-child {
					@include phone {
						margin-bottom: 0;
					}
				}

				img {
					width: 100%;
				}
			}
		}

		.artifact-grid {
			right: -140px;
			bottom: -30px;

			@include phone {
				display: none;
			}
		}
	}
}
