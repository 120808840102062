.categories {
	margin: 100px auto 80px auto;

	@include phone {
		margin: 30px auto 30px auto;

		@include full-width;
	}

	ul {
		list-style: none;
		display: flex;
		justify-content: center;
		margin: 0;
		padding: 0;

		@include phone {
			justify-content: flex-start;
			padding: 25px 20px;
			overflow-x: scroll;
			overflow-y: hidden;
			white-space: nowrap;
			-webkit-overflow-scrolling: touch;
			background: linear-gradient(90deg,#fff 0,rgba(255,255,255,0)),linear-gradient(-90deg,#fff 0,rgba(255,255,255,0)) 100% 0,radial-gradient(farthest-side at 0 50%,rgba(0,0,0,.1),rgba(0,0,0,0)),radial-gradient(farthest-side at 100% 50%,rgba(0,0,0,.1),rgba(0,0,0,0)) 100% 0;
			background-repeat: no-repeat;
			background-color: #fff;
			background-size: 100px 100%,100px 100%,14px 100%,14px 100%;
			background-attachment: local,local,scroll,scroll;
		}

		li {
			margin-right: 30px;

			&:last-child {
				margin-right: 0;
			}

			a {
				font-family: $font-primary;
				font-size: 15px;
				color: $base-navy;
				font-weight: 600;
				padding: 6px 10px;
				border-radius: 4px;
				transition: color 0s;

				&:hover {
					background: $base-light-navy;
				}

				&.active {
					background: $accent-yellow;
					color: #fff;
				}
			}
		}
	}
}
