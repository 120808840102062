.btn {
	border-radius: 4px;
	padding: 14px 18px;
	font-size: 14px;
	font-family: $font-primary;
	text-transform: uppercase;
	font-weight: 800;
	color: $base-navy;
	text-decoration: none;
	display: inline-block;
	box-shadow: 0 0 4px 0 rgba(0,0,0,0.04);
	transition: box-shadow 0.5s;

	&:hover {
		box-shadow: 0 0 10px 0 rgba(0,0,0,0.09);
	}

	&.btn-teal {
		background: $accent-teal;
		color: #fff;
		border: 1px solid #5EC1AA;
	}

	&.btn-white {
		background: #fff;
		border: 1px solid rgba(51,71,89,0.12);
	}

	&.btn-gp {
		background: #accc5b;
		color: #fff;
	}

	&.btn-do {
		background: #0080ff;
		color: #fff;
	}
}
