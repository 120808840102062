.support {
	position: relative;
	z-index: 3;

	@include full-width;

	.artifact-grid-vertical {
		top: 150px;
		left: -20px;
	}

	.artifact-circle-yellow {
		bottom: 50px;
		right: -80px;
	}

	.container {
		position: relative;
		z-index: 4;

		@include phone {
			padding: 0 15px;
		}
	}

	.columns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 80px 0 40px 0;

		@include phone {
			padding: 40px 0 20px 0;
		}

		.column:first-child {
			flex: 0 0 50%;

			@include phone {
				flex: 0 0 100%;
				margin-bottom: 10px;
			}

			h1 {
				@include phone {
					font-size: 34px;
					margin-bottom: 15px;
				}
			}

			p {
				color: $base-navy;
				font-size: 18px;
				line-height: 34px;

				@include phone {
					font-size: 16px;
					line-height: 30px;
				}

				.docs-hint {
					font-size: 14px;
					display: none;
					color: $base-navy;
					padding-top: 15px;
					line-height: 21px;

					i {
						vertical-align: -5px;
						width: 20px;
						height: 20px;
						background-size: 20px 20px;
					}
				}

				a {
					color: $base-navy;

					&::before {
						background-color: $base-navy;
					}
				}
			}
		}

		.column:last-child {
			flex: 0 0 43%;

			@include phone {
				flex: 0 0 100%;
				margin-bottom: 20px;
			}
		}
	}

	.support-request {
		background: #fff;
		border: 1px solid rgba(33,52,76,0.06);
		box-shadow: 0 1px 25px 1px rgba(0,0,0,0.06);
		border-radius: 6px;
		padding: 30px;
		margin-bottom: 60px;

		@include phone {
			margin-bottom: 40px;
			padding: 25px;
		}

		.columns {
			padding: 0;
			align-items: flex-start;

			.column:first-child {
				flex: 0 0 48%;

				@include phone {
					flex: 0 0 100%;
					margin-bottom: 25px;
				}
			}

			.column:last-child {
				flex: 0 0 48%;

				@include phone {
					flex: 0 0 100%;
					margin-bottom: 0;
				}

				p:last-child {
					text-align: right;

					@include phone {
						text-align: left;
					}
				}
			}
		}
	}

	.customization-request {
		text-align: center;
		margin-bottom: 60px;

		@include phone {
			margin-bottom: 40px;
		}

		h4 {
			font-size: 19px;
			font-weight: 600;
			margin-bottom: 15px;

			i {
				vertical-align: -4px;
				margin-right: 5px;
			}
		}

		p {
			color: $base-navy;
			font-size: 14px;

			a {
				color: $base-navy;

				&::before {
					background-color: $base-navy;
				}
			}
		}
	}
}
