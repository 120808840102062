.themes {
	.artifact-grid-vertical {
		top: 150px;
		left: -20px;

		@include phone {
			transform: rotate(90deg);
			left: -120px;
			top: 210px;
			z-index: -1;
		}
	}

	.theme-list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		max-width: 1000px;
		margin: 0 auto;

		.theme-card {
			flex: 0 0 47%;
			margin-bottom: 60px;

			@include phone {
				flex: 0 0 100%;
				margin-bottom: 40px;
			}
		}
	}
}
