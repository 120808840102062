.ghost-collection {
	background: #fff;
	border: 1px solid rgba(51,71,89,0.12);
	box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
	border-radius: 4px;
	padding: 20px;

	.columns {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.column:first-child {
			flex: 0 0 460px;

			@include phone {
				flex: 0 0 100%;
				text-align: center;
				margin-bottom: 20px;
			}

			h2 {
				font-size: 27px;
				font-weight: 600;

				@include phone {
					font-size: 22px;
					margin-bottom: 10px;
				}

				em {
					font-style: normal;

					@include phone {
						display: block;
						margin-bottom: 20px;
					}
				}

				i {
					width: 34px;
					height: 34px;
					background-size: 34px 34px;
					vertical-align: -7px;

					@include phone {
						width: 28px;
						height: 28px;
						background-size: 28px 28px;
					}
				}

				span {
					margin-left: 10px;
					background: $accent-yellow;
					padding: 6px 8px;
					border-radius: 4px;
					color: #fff;
					font-size: 17px;
					font-weight: 600;
					font-family: $font-secondary;
					vertical-align: 2px;

					@include phone {
						margin: 0;
						font-size: 16px;
					}
				}
			}

			p {
				color: $text-gray;

				@include phone {
					margin-bottom: 15px;
				}
			}
		}

		.column:last-child {
			flex: 0 0 500px;
			padding-left: 70px;
			border-left: 1px solid #e9e9e9;

			@include phone {
				flex: 0 0 100%;
				border: none;
				padding-left: 0;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				li {
					color: #798792;
					font-weight: 500;
					background: url("/assets/img/icon-check-bullet.svg") no-repeat 0 4px;
					padding-left: 30px;
					font-size: 15px;
					margin-bottom: 15px;
					line-height: 26px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
