.affiliates + footer {
	padding-top: 0;
}

.affiliates {
	position: relative;
	z-index: 3;

	@include full-width;

	.artifact-grid-vertical {
		top: 150px;
		left: -20px;

		@include phone {
			left: -60px;
		}
	}

	.artifact-circle-blue {
		top: 400px;
		right: -130px;
		z-index: 1;

		@include phone {
			display: none;
		}
	}

	.text-link {
		color: $base-navy;
		font-size: 15px;
		font-weight: 600;

		&::before {
			background-color: $base-navy;
		}
	}

  .page-title {
		padding: 80px 0 30px 0;
		max-width: 680px;
		margin-bottom: 0;
		position: relative;
		z-index: 4;

		@include phone {
			padding: 50px 0 0 0;
		}

		h1 {
			margin: 0;
		}

		p {
			margin: 30px 0;
		}
	}

	.affiliate-themes {
		@include phone {
			padding: 0;
		}

		img {
			display: block;
			margin: 0 auto;

			@include phone {
				width: 100%;
				height: auto;
			}
		}
	}

	hr {
		background: #e1e1e1;
		height: 1px;
		width: 100%;
		border: none;
		margin-bottom: 25px;
		display: block;
	}

  .program-details {
		background: $base-light-navy;
		padding: 80px 0;
		border-top: 1px solid #f4f4f4;
		margin-bottom: 0 !important;
		position: relative;
		z-index: 2;

		@include full-width;

		@include phone {
			padding: 40px 0 50px 0;
			margin-bottom: 40px;
		}

		.columns {
			display: flex;
			gap: 80px;

			@include phone {
				flex-wrap: wrap;
				gap: 20px;
			}

			.column {
				width: 50%;

				@include phone {
					width: 100%;
				}

				&:last-child {
					background: #fff;
					border-radius: 4px;
					border: 1px solid rgba(151,151,151,0.20);
					box-shadow: 0 0 6px 0 rgba(0,0,0,0.05);
					padding: 35px 35px 25px 35px;

					h2 {
						font-size: 30px;
					}

					ul {
						list-style: none;
						padding: 0;
						margin: 0;

						li {
							font-size: 15px;
							line-height: 28px;
							color: #222;
							display: flex;
							counter-increment: large-numbers;
							gap: 20px;
							margin-bottom: 25px;

							img {
								width: 100%;
								height: auto;
								border-radius: 6px;
								border: 1px solid #eaeaea;
								box-shadow: 0 2px 8px rgba(0,0,0,0.05);
								display: block;
								margin-bottom: 15px;
							}
							
							code {
								padding: 2px 5px;
								line-height: normal;
								display: inline-block;
								font-size: 15px;
								background: $base-light-navy;
								border: 1px solid #eee;
								border-radius: 6px;
								word-break: break-all;
							}

							&:first-child,
							&:last-child {
								span {
									display: block;
									margin-bottom: 15px;
								}

								.btn {
									line-height: normal;
								}
							}

							&::before {
								content: counter(large-numbers);
								display: flex;
								flex-shrink: 0;
								background: $base-navy;
								border-radius: 100%;
								width: 32px;
								height: 32px;
								line-height: 32px;
								display: inline-block;
								color: #fff;
								font-weight: bold;
								text-align: center;
								justify-content: center;
								align-items: center;
								margin-top: 6px;
							}
						}
					}
				}

				h2 {
					font-size: 35px;
					margin-bottom: 20px;
				}

				h3 {
					margin-bottom: 15px;
				}

				p {
					color: $text-gray;

					&.intro {
						margin-bottom: 35px;
					}

					b {
						color: #222;
					}
				}
			}
		}
  }
}
