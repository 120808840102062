.translator {
  text-align: center;

  .page-title {
    margin-bottom: 40px;

    h1 {
      span {
        background: $accent-yellow;
        padding: 6px 8px;
        border-radius: 4px;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        font-family: $font-secondary;
        vertical-align: 5px;
      }
    }
  }

  .drop-area {
    border: 3px dashed #94A3B8;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 80px;
    gap: 12px;
    max-width: 350px;
    margin: 0 auto 40px auto;
    opacity: 0.8;
    transition: opacity 300ms;

    &:hover {
      opacity: 1;
    }

    p {
      margin: 0;
      font-weight: 600;
      color: #94A3B8;
      font-size: 14px;
    }
  }

  .drop-area.dragover {
    background-color: $base-light-navy;
  }

  .instructions {
    font-size: 14px;
    text-align: center;
    margin-bottom: 40px;

    code {
      font-size: 13px;
      font-weight: 600;
      padding: 2px;
      background: #EAECF1;
      border-radius: 2px;
    }
  }

  .settings {
    max-width: 540px;
    width: 100%;
    margin: 0 auto 40px auto;
    display: flex;
    gap: 20px;
    align-items: center;

    @include phone {
      flex-wrap: wrap;
      justify-content: center;
    }

    .uploaded-file {
      background: #fff;
      border: 1px solid rgba(151,151,151,0.20);
      box-shadow: 0 0 6px 0 rgba(0,0,0,0.05);
      border-radius: 4px;
      font-size: 16px;
      font-family: $font-secondary;
      color: $base-navy;
      padding: 14px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        appearance: none;
        border: none;
        background: none;
        cursor: pointer;
        margin: 0;
        padding: 0;
        opacity: 0.6;
        transition: opacity 300ms;

        &:hover {
          opacity: 1;
        }
      }
    }

    > svg {
      width: 50px;
      height: 50px;

      @include phone {
				transform: rotate(90deg);
        width: 20px;
        height: 20px;
			}
    }
  }

  .upsell {
    margin-bottom: 30px;
    margin-top: -10px;

    span {
      background: $accent-green;
      padding: 2px 6px;
      border-radius: 2px;
      color: #fff;
      font-weight: bold;
      vertical-align: 0px;
      margin-left: 3px
    }
  }

  .translator-wrapper {
    display: none;
    margin-bottom: 40px;

    .btn {
      cursor: pointer;
    }

    .translations-wrapper {
      margin: 0 auto 40px auto;
      border: 1px solid #E2E8F0;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      max-width: 700px;
      width: 100%;

      .header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 18px 20px;
        gap: 10px;
        background: #F8FAFC;
        border-bottom: 1px solid #E2E8F0;
        border-radius: 6px 6px 0px 0px;

        div {
          width: 50%;
          font-size: 13px;
          font-weight: bold;
          text-transform: uppercase;
          text-align: left;
        }
      }
    }

    .translations-container {
      div {
        display: flex;
        gap: 10px;
        padding: 10px 10px 0 10px;

        &:last-child {
          padding-bottom: 10px;
        }

        span {
          width: 50%;
          border: 1px solid #CBD5E1;
          border-radius: 6px;
          padding: 13px;
          font-size: 14px;
          font-family: $font-secondary;
          text-align: left;

          &.key {
            background: $base-light-navy;
          }
        }
      }
    }
  }

  .help {
    text-align: center;

    .text-link {
      color: $base-navy;
      font-size: 14px;
  
      &::before {
        background-color: $base-navy;
      }

      svg {
        margin-left: 5px;
        vertical-align: -3px;
      }
    }
  }
}
