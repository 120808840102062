@for $i from 1 through 6 {
  h#{7 - $i} {
    font-family: $font-primary;
    font-weight: 300;
    margin: 0 0 25px 0;
    color: $base-navy;
  }
}

h1 {
  font-size: 38px;
  line-height: 52px;
}

h2 {
  font-size: 38px;

  @include phone {
    font-size: 34px;
  }
}

h3 {
  font-size: 24px;
}

p {
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 25px;
  font-weight: normal;
  color: $base-gray;
}

b {
  font-weight: 600;
}

a {
  text-decoration: none;
  outline: none;
  transition: opacity 0.5s, color 0.5s;
  position: relative;

  &.text-link {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1.5px;
      border-radius: 5px;
      background-color: #fff;
      bottom: -2px;
      left: 0;
      transform-origin: right;
      transform: scaleX(1);
      transition: transform .3s ease-in-out;
    }

    &:hover::before {
      transform-origin: left;
      transform: scaleX(0);
    }

    &.reversed {
      &::before {
        transform: scaleX(0);
      }

      &:hover::before {
        transform: scaleX(1);
      }
    }
  }
}

.link {
  color: $base-navy;
  font-weight: 600;
  position: relative;
  text-decoration: underline;
}

.capitalize {
  text-transform: capitalize;
}
