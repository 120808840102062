.docs {
	.artifact-grid-vertical {
		top: 150px;
		left: -20px;

		@include phone {
			transform: rotate(90deg);
			left: -120px;
			top: 210px;
			z-index: -1;
		}
	}

	.theme-docs {
		margin: 0 auto;
		position: relative;
		z-index: 2;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 30px;
		grid-row-gap: 30px;

		@include tablet {
			grid-template-columns: repeat(2, 1fr);
		}

		@include phone {
			grid-template-columns: repeat(1, 1fr);
		}

		.docs-card {
			background: #fff;
			border: 1px solid rgba(51,71,89,0.12);
			box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
			border-radius: 4px;
			padding: 20px;

			img {
				display: block;
				border-radius: 4px;
				object-fit: cover;
				object-position: top center;
				width: 100%;
				height: 160px;
				margin-bottom: 20px;
				border: 1px solid rgba(51,71,89,0.08);
			}

			h2 {
				margin: 0 0 8px 0;
				font-size: 21px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.title {
					font-weight: 600;
					color: $base-navy;
				}

				.version {
					background: $base-navy;
					padding: 5px 7px;
					border-radius: 4px;
					color: #fff;
					font-size: 14px;
					font-weight: 700;
					font-family: $font-primary;
					display: none;
				}
			}

			p {
				margin: 0;
				font-size: 15px;
				color: $text-gray;
			}
		}
	}

}

.documentation-container {
	display: flex;
	justify-content: space-between;

	@include phone {
		flex-wrap: wrap;
		padding-top: 0;
	}

	aside {
		flex: 0 0 25%;

		@include phone {
			flex: 0 0 100%;
			border-bottom: 1px solid #eaeaea;
			margin-bottom: 25px;
			padding-bottom: 10px;
		}

		.inner {
			position: -webkit-sticky;
			position: sticky;
			top: 25px;
		}

		strong {
			font-size: 17px;
			font-weight: 600;
			margin-bottom: 10px;
			display: block;
			margin-bottom: 20px;

			i {
				vertical-align: -6px;
				margin-right: 2px;
				margin-left: -2px;
			}
		}
	}

	.post-content {
		.block {
			margin-bottom: 35px;
			padding-bottom: 5px;
			border-bottom: 1px solid #eaeaea;

			&:last-child {
				border-bottom: none;
				margin-bottom: 0;
			}
		}

		.hint-box,
		blockquote {
			border: none;
			font-style: normal;
			background: lighten($base-navy, 75%);
			padding: 25px;
			border-radius: 4px;
			margin-bottom: 40px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}

		video {
			width: 100%;
			height: auto;
			margin-bottom: 40px;
			border-radius: 6px;
			border: 1px solid #eaeaea;
		}

		.changelog {
			position: relative;

			&::before {
				position: absolute;
				content: "";
				width: 4px;
				height: 100%;
				left: 0;
				top: 13px;
				background-color: #fafafa;
			}

			.changelog-entry {
				margin-bottom: 40px;
				padding-left: 40px;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					border-radius: 50%;
					top: 15px;
					left: -5px;
					width: 14px;
					height: 14px;
					opacity: .8;
					background: $accent-teal;
				}

				.version {
					font-size: 17px;
					margin-bottom: 25px;

					span {
						padding: 7px 11px;
						font-size: 21px;
						display: inline-block;
						background: $base-navy;
						color: #fff;
						border-radius: 4px;
						font-weight: 700;
						font-family: $font-primary;
						margin-right: 20px;
					}
				}

				.content {
					h3 {
						font-size: 20px;
						font-weight: 600;
						margin-bottom: 15px;
					}

					ul li {
						word-break: break-word;
					}
				}
			}
		}
	}
}

.theme-details {
	border: 1px solid #DFDFDF;
	box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
	border-radius: 4px;
	margin-bottom: 55px;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		line-height: initial;
		display: flex;
		flex-wrap: wrap;

		li {
			padding: 16px;
			color: $base-navy;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 15px;
			flex: 0 0 32%;
			border-right: 1px solid #DFDFDF;

			@include phone {
				border-right: none;
				border-bottom: 1px solid #DFDFDF;
				flex: 0 0 100%;
			}

			&:last-child {
				border: none;
			}

			label {
				flex: 0 0 48%;

				i {
					vertical-align: -5px;
					margin-right: 5px;
				}
			}

			span {
				flex: 0 0 46%;
				font-weight: 600;
			}
		}
	}
}
