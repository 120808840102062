.hosting {
	.artifact-grid-vertical {
		top: 150px;
		left: -20px;

		@include phone {
			transform: rotate(90deg);
			left: -120px;
			top: 170px;
			z-index: -1;
		}
	}

	.columns {
		display: flex;
		justify-content: space-between;
		align-content: center;
		max-width: 860px;
		margin: 0 auto;
		flex-wrap: wrap;
	}

	.box {
		text-align: left;
		flex: 0 0 48%;

		@include phone {
			flex: 0 0 100%;
		}

		&:first-child {
			@include phone {
				margin-bottom: 40px;
			}
		}

		.box-inner {
			background: #fff;
			padding: 30px 40px 40px 40px;
			border: 1px solid rgba(51,71,89,0.12);
			box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
			border-radius: 6px;
			margin-bottom: 30px;
			transition: box-shadow 0.5s;
			text-align: center;

			&:hover {
				box-shadow: 0 2px 33px 21px #dee8ee;
			}

			img {
				max-width: 100%;
				display: block;
				margin: 0 auto 20px auto;
			}

			h3 {
				font-weight: 600;
				font-size: 21px;
			}

			p {
				font-size: 16px;
				margin-bottom: 30px;
				color: $text-gray;
			}

			ul {
				list-style: none;
				padding: 0;
				text-align: left;
				margin-bottom: 40px;

				li {
					color: #6A7987;
					font-weight: 500;
					background: url("/assets/img/icon-bullet-triangle.svg") no-repeat;
					padding-left: 30px;
					font-size: 15px;
					margin-bottom: 22px;
				}
			}
		}

		.suggestion {
			font-size: 14px;
			max-width: 80%;
			margin: 0 auto;
			display: flex;
			background: url("/assets/img/icon-info.svg") no-repeat 0px 4px;
			padding-left: 30px;
			color: $base-navy;
			line-height: 24px;

			span {
				opacity: 0.8;
			}
		}
	}
}
