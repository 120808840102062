.showcase-card {
	background: $base-light-navy;
	border: 1px solid rgba(51,71,89,0.05);
	border-radius: 4px;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&.hidden {
		display: none;
	}

	.featured-image {
		margin-bottom: 0;

		img {
			display: block;
			border-radius: 0;
			border: none;
			box-shadow: none;
			object-fit: cover;
			object-position: top center;
			width: 100%;
			height: 200px;
		}
	}

	.content {
		padding: 20px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		h3 {
			margin: 0 0 5px 0;
			font-size: 18px;
			line-height: 30px;
			color: $base-navy;
			font-weight: 500;
		}

		p {
			margin: 0 0 15px 0;
			font-size: 14px;
			color: $text-gray;
			line-height: 26px;
			flex-grow: 1;
		}

		.theme {
			span {
				background: $base-navy;
				padding: 6px 8px;
				border-radius: 4px;
				color: #fff;
				font-size: 12px;
				font-weight: 600;
				font-family: $font-secondary;
			}
		}
	}
}
