@mixin phone {
  @media only screen and (max-width: $phone) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin tablet-landscape {
  @media only screen and (max-width: $tablet-landscape) {
    @content;
  }
}

@mixin desktop-wide {
  @media only screen and (min-width: $desktop-wide) {
    @content;
  }
}

@mixin desktop-wider {
  @media only screen and (min-width: $desktop-wider) {
    @content;
  }
}

@mixin desktop-extended {
  @media only screen and (min-width: $desktop-extended) {
    @content;
  }
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin animated($name, $time, $easing: linear) {
  animation-duration: $time;
  animation-fill-mode: both;
  animation-name: $name;
  animation-timing-function: $easing;
}

@mixin full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

@mixin logo-light {
  svg {
    #mark {
      stroke: $base-navy;
    }

    #text {
      fill: $base-navy;
    }
  }
}
