.breadcrumbs {
	list-style: none;
	margin: 0 0 15px 0;
	padding: 0;

	@include phone {
		margin-bottom: 10px;
	}

	li {
		display: inline-block;
		font-weight: 600;
		font-size: 15px;

		&.active {
			color: $accent-yellow;
		}

		&.arrow {
			color: $base-navy;
			vertical-align: -1px;
			margin: 0 2px;
		}

		a {
			color: $base-navy;

			&::before {
				background-color: $base-navy;
			}
		}
	}
}
