*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: #fff;
  color: #3f3f3f;
  font-family: $font-secondary;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.site-container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  flex-grow: 1;

  @include tablet-landscape {
    padding: 0 20px;
  }

  @include tablet {
    padding: 0 20px;
  }

  @include phone {
    padding: 0 20px;
  }
}

.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

button {
  outline: none;
}

input,
select,
textarea {
  -webkit-appearance: none !important;
  outline: none;
}

.medium-zoom-overlay {
  z-index: 999999;
}

.medium-zoom-image--opened {
  z-index: 9999999;
  object-fit: cover;
  object-position: top center;
  height: auto !important;
  border-radius: 3px;
}
