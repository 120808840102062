.hint {
	background: #F9F9FA;
	border: 1px solid #E9EBED;
	border-radius: 4px;
	padding: 25px;

	h4 {
		font-size: 17px;
		margin-bottom: 18px;
		color: $base-navy;
		font-weight: 600;

		i {
			vertical-align: -5px;
			margin-right: 5px;
		}
	}

	p,
	ul {
		a {
			color: $base-navy;

			&::before {
				background-color: $base-navy;
			}
		}
	}

	p {
		margin: 0;
		font-size: 14px !important;
		line-height: normal !important;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;

		li {
			background-image: url("/assets/img/icon-check-green.svg");
			background-repeat: no-repeat;
			background-position: 0 6px;
			background-size: 16px 16px;
			padding-left: 28px;
			margin-bottom: 15px;
			line-height: 26px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
