.blog {
	@include full-width;

	.artifact-stripe {
		top: 380px;
		left: -50px;

		@include phone {
			display: none;
		}
	}

	.featured-post {
		background: #fff;
		border: 1px solid rgba(51,71,89,0.12);
		box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
		border-radius: 4px;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 2;

		@include phone {
			flex-wrap: wrap;
		}

		.content {
			padding: 20px;

			@include phone {
				order: 2;
				flex: 0 0 100%;
			}

			h2 {
				font-size: 30px;
				line-height: 42px;
				margin-bottom: 20px;

				@include phone {
					font-size: 22px;
					line-height: 30px;
				}

				a {
					color: $base-navy;
				}
			}

			.meta {
				color: $accent-yellow;
				font-weight: 600;
				font-size: 14px;
				margin-bottom: 20px;

				a {
					color: $accent-yellow;
					font-size: 14px;

					&::before {
						background-color: $accent-yellow;
					}
				}

				span {
					margin: 0 5px;
				}
			}

			p {
				color: $base-navy;
				margin: 0 0 20px 0;

				@include phone {
					font-size: 15px;
					line-height: 30px;
				}
			}

			.text-link {
				color: $base-navy;
				font-size: 15px;
				font-weight: 600;

				&::before {
					background-color: $base-navy;
				}
			}
		}

		.image {
			margin: 0 0 0 25px;
			flex: 0 0 510px;

			@include phone {
				order: 1;
				flex: 0 0 100%;
				margin: 0;
			}

			img {
				display: block;
				border-radius: 4px;
				object-fit: cover;

				@include phone {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	.post-list {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		margin: -3%;

		@include phone {
			flex-wrap: wrap;
			margin: 0;
		}

		.post-card {
			flex: 0 0 30%;
			margin: 1.5%;

			@include phone {
				flex: 0 0 100%;
				margin: 0 0 25px 0;
			}
		}
	}
}
