.customization {
	position: relative;
	z-index: 3;

	@include full-width;

	.artifact-grid-vertical {
		top: 150px;
		left: -20px;
	}

	.artifact-circle-yellow {
		bottom: -200px;
		right: -80px;
	}

	.columns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 80px 0 40px 0;
		position: relative;
		z-index: 4;

		@include phone {
			padding: 40px 0 20px 0;
		}

		.column:first-child {
			flex: 0 0 42%;

			@include phone {
				flex: 0 0 100%;
				margin-bottom: 40px;
			}

			h1 {
				@include phone {
					font-size: 34px;
					margin-bottom: 15px;
				}
			}

			p,
			ul {
				color: $base-navy;
				font-size: 18px;
				line-height: 34px;

				@include phone {
					font-size: 16px;
					line-height: 30px;
					margin-bottom: 20px;
				}
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0 0 30px 0;

				@include phone {
					margin-bottom: 20px;
				}

				li {
					background: url("/assets/img/icon-check-green.svg") no-repeat 0 8px;
					padding-left: 30px;
					margin-bottom: 10px;
				}
			}
		}

		.column:last-child {
			flex: 0 0 48%;
			background: #fff;
			border: 1px solid rgba(33,52,76,0.06);
			box-shadow: 0 1px 25px 1px rgba(0,0,0,0.06);
			border-radius: 6px;
			padding: 30px;

			p:first-of-type {
				margin-top: 0;
			}

			@include phone {
				flex: 0 0 100%;
				padding: 25px;
			}
		}
	}
}
