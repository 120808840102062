.theme-card {
	&:nth-child(even) {
		img:hover {
			transform: rotate(1deg);
		}
	}

	&:nth-child(odd) {
		img:hover {
			transform: rotate(-1deg);
		}
	}

	img {
		border: 1px solid rgba(51,71,89,0.12);
		box-shadow: 0 0 16px 0 rgba(0,0,0,0.15);
		border-radius: 4px;
		display: block;
		margin-bottom: 25px;
		width: 100%;
		height: auto;
		transition: all 0.5s;
	}

	h2 {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 15px;

		a {
			color: $base-navy;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		span {
			background: $accent-yellow;
			padding: 6px 8px;
			border-radius: 4px;
			color: #fff;
			font-size: 17px;
			font-weight: 600;
			font-family: $font-secondary;
		}
	}

	p {
		color: $text-gray;
		font-size: 17px;
		margin: 0 0 20px 0;
	}

	.btn {
		margin-right: 20px;
	}

	.text-link {
		color: $base-navy;
		font-size: 15px;
		font-weight: 600;

		&::before {
			background-color: $base-navy;
		}
	}

	i {
		vertical-align: -6px;
		margin-left: 5px;
	}
}

.theme-card-small {
	border: 1px solid rgba(51,71,89,0.12);
	box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	transition: box-shadow 0.5s;

	&:hover {
		box-shadow: 0 2px 25px 15px #dee8ee;
	}

	.thumb {
		flex: 0 0 140px;
		margin: 12px 5px 12px 12px;
		height: 85%;
		border-radius: 4px;
		background-size: cover;
		background-position: center center;
		border-right: 1px solid #f1f1f1;
	}

	.inner {
		padding: 15px;

		h4 {
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 10px;

			span {
				background: $accent-yellow;
				padding: 4px 5px;
				border-radius: 4px;
				color: #fff;
				font-size: 13px;
				font-weight: 600;
				font-family: $font-secondary;
				margin-left: 5px;
				vertical-align: 1px;
			}
		}

		p {
			color: $text-gray;
			font-size: 13px;
			line-height: 24px;
			margin: 0;
		}
	}
}
