.theme {
	.header {
		padding-top: 60px;
		margin-bottom: 60px;
		display: flex;
		justify-content: space-between;

		@include phone {
			padding-top: 30px;
			margin-bottom: 40px;
		}

		.columns {
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: end;

			@include phone {
				flex-wrap: wrap;
			}

			.column:first-child {
				flex: 0 0 390px;

				@include phone {
					flex: 0 0 100%;
					order: 2;
				}
			}

			.column:last-child {
				margin-left: 90px;

				@include phone {
					flex: 0 0 100%;
					margin-left: 0;
					margin-bottom: 30px;
					order: 1;
				}

				img {
					border: 1px solid rgba(51,71,89,0.12);
					box-shadow: 0 0 16px 0 rgba(0,0,0,0.15);
					border-radius: 6px;
					display: block;
					width: 100%;
					height: auto;
				}
			}
		}

		h1 {
			font-size: 38px;
			margin-bottom: 12px;

			@include phone {
				font-size: 34px;
			}

			span {
				margin-left: 10px;
				background: $accent-yellow;
				padding: 6px 8px;
				border-radius: 4px;
				color: #fff;
				font-size: 17px;
				font-weight: 600;
				font-family: $font-secondary;
				vertical-align: 6px;
			}
		}

		p {
			font-size: 18px;
			color: $text-gray;
			margin: 0 0 20px 0;

			@include phone {
				font-size: 16px;
				line-height: 30px;
			}
		}

		.btn {
			margin-right: 20px;
		}

		.text-link {
			color: $base-navy;
			font-size: 15px;
			font-weight: 600;

			&::before {
				background-color: $base-navy;
			}
		}

		.icon-link {
			vertical-align: -6px;
			margin-left: 5px;
		}

		.details {
			border: 1px solid #DFDFDF;
			box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
			border-radius: 4px;
			margin-top: 30px;

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					border-bottom: 1px solid #DFDFDF;
					padding: 16px;
					color: $base-navy;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 15px;

					&:last-child {
						border: none;
					}

					label {
						flex: 0 0 48%;

						i {
							vertical-align: -5px;
							margin-right: 5px;
						}
					}

					span {
						flex: 0 0 44%;
						font-weight: 600;
					}
				}
			}
		}
	}

	.content {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include phone {
			margin-bottom: 20px;
		}

		.description {
			flex: 0 0 62%;

			@include phone {
				flex: 0 0 100%;
			}

			h3 {
				font-size: 28px;
				margin-bottom: 30px;
				font-weight: 500;

				@include phone {
					font-size: 24px;
					margin-bottom: 20px;
				}
			}

			p {
				color: $base-navy;

				&:first-child {
					margin-top: 0;
				}
			}

			figure {
				margin: 0 0 50px 0;

				@include phone {
					margin: 0 0 20px 0;
				}

				&.gallery {
					display: grid;
					width: 100%;
					gap: 20px;
					grid-auto-columns: 1fr;
					grid-gap: 20px;
					will-change: transform;
					grid-template-columns: repeat(2,1fr);
				}

				img {
					border: 1px solid #eaeaea;
					border-radius: 6px;
					height: auto;
					max-width: 100%;
				}
			}

			ul {
				color: $base-navy;
				margin: 0 0 30px 0;
				padding: 0 0 0 5px;
				list-style: none;
				column-count: 2;

				@include phone {
					column-count: 1;
					margin: 0 0 20px 0;
				}

				&:last-child {
					margin-bottom: 15px;
				}

				li {
					color: $base-navy;
					font-weight: 500;
					background: url("/assets/img/icon-bullet-triangle.svg") no-repeat 0 7px;
					padding-left: 30px;
					font-size: 15px;
					margin-bottom: 15px;
					line-height: 26px;

					a {
						color: $base-navy;
						transition: opacity 0.5s, color 0.5s;

						&::before {
							content: '';
							position: absolute;
							width: 100%;
							height: 1.5px;
							border-radius: 5px;
							background-color: $base-navy;
							bottom: -2px;
							left: 0;
							transform-origin: right;
							transform: scaleX(1);
							transition: transform .3s ease-in-out;
						}

						&:hover::before {
							transform-origin: left;
							transform: scaleX(0);
						}
					}

					ul {
						column-count: 1;
						padding-top: 15px;
						margin-bottom: 0;
						list-style: circle;
						padding-left: 12px;

						li {
							background: none;
							padding-left: 0;

							&:last-child {
								margin-bottom: 5px;
							}
						}
					}
				}
			}
		}

		aside {
			flex: 0 0 32%;
			position: sticky;
			top: 40px;
			height: 25vh;

			@include phone {
				flex: 0 0 100%;
				position: static;
				top: auto;
				height: auto;
			}

			.btn-teal {
				width: 100%;
				text-align: center;
				margin-bottom: 30px;
			}

			.ghost-collection {
				border: 1px solid rgba(51,71,89,0.12);
				box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
				padding: 30px 30px 0 30px;
				text-align: center;
				border-radius: 4px;

				h2 {
					font-size: 19px;
					font-weight: 600;
					margin-bottom: 20px;

					i {
						vertical-align: -5px;
					}
				}

				p {
					color: $base-navy;
					font-size: 15px;
					line-height: 26px;
					margin: 0 0 20px 0;
				}

				img {
					display: block;
					max-width: 100%;

					@include phone {
						width: 100%;
					}
				}

				.btn {
					margin-bottom: 22px;
				}
			}
		}
	}

	.more-themes {
		padding-top: 20px;

		h2 {
			font-size: 28px;
			margin-bottom: 20px;
			font-weight: 500;
		}

		.columns {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.theme-card-small {
				flex: 0 0 32%;

				@include phone {
					flex: 0 0 100%;
					margin-bottom: 20px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
