@charset "utf-8";

/* ========================================
Vendor
========================================= */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Nunito+Sans:wght@300;400;600;800&display=swap');
@import "vendor/normalize";
@import "vendor/tiny-slider";

/* ========================================
Globals
========================================= */
@import "base/variables";
@import "base/mixins";
@import "base/typography";
@import "base/globals";

/* ========================================
Layout
========================================= */
@import "layout/header";
@import "layout/footer";

/* ========================================
Components
========================================= */
@import "components/button";
@import "components/artifacts";
@import "components/icons";
@import "components/theme-card";
@import "components/titles";
@import "components/hint";
@import "components/form";
@import "components/post-card";
@import "components/showcase-card";
@import "components/faqs";
@import "components/breadcrumbs";
@import "components/code";
@import "components/ghost-collection";
@import "components/hello-bar";
@import "components/toc";
@import "components/modal";
@import "components/category-filter";

/* ========================================
Pages
========================================= */
@import "pages/homepage";
@import "pages/themes";
@import "pages/theme";
@import "pages/hosting";
@import "pages/customization";
@import "pages/services";
@import "pages/support";
@import "pages/blog";
@import "pages/post";
@import "pages/legal";
@import "pages/docs";
@import "pages/showcase";
@import "pages/affiliates";
@import "pages/translator";
