.post-toc {
	ol {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			margin-bottom: 15px;

			a {
				color: $text-gray;
				font-size: 15px;
				line-height: 26px;

				&.is-active-link {
					font-weight: 500;
					color: darken($text-gray, 20%);
				}

				&::before {
					display: none;
				}
			}

			ol {
				li {
					margin: 8px 0;

					&:last-child {
						margin-bottom: 0;
					}

					a {
						padding-left: 15px;
						font-size: 14px;
						display: block;

						&::before {
							display: inline;
							top: 2px;
						}
					}
				}
			}
		}
	}
}
