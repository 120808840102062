.form {
	p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	input[type="text"],
	input[type="email"],
	select,
	textarea {
		background: #fff;
		border: 1px solid rgba(151,151,151,0.20);
		box-shadow: 0 0 6px 0 rgba(0,0,0,0.05);
		border-radius: 4px;
		font-size: 16px;
		font-family: $font-secondary;
		color: $base-navy;
		padding: 14px;
		width: 100%;
		display: block;
	}

	button,
	select {
		cursor: pointer;
	}

	select {
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		background-image: url("/assets/img/icon-arrow-down.svg");
		background-repeat: no-repeat;
		background-size: 14px 7px;
		background-position: calc(100% - 18px) center;
	}
}
