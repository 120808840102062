.page-title,
.section-title {
	text-align: center;
	max-width: 710px;
	margin: 0 auto;

	h1,
	h2 {
		font-size: 42px;
		margin: 0 0 15px 0;
		color: $base-navy;

		@include phone {
			font-size: 34px;
			line-height: 46px;
		}
	}

	p {
		color: $text-gray;
		font-size: 18px;
		line-height: 34px;
		margin: 0;

		@include phone {
			font-size: 16px;
			line-height: 30px;
		}
	}
}

.page-title {
	padding-top: 50px;
	margin-bottom: 60px;

	@include phone {
		margin-bottom: 40px;
		padding-top: 30px;
	}

	&.with-post {
		margin-bottom: 40px;
		max-width: 800px;

		.breadcrumbs {
			margin-bottom: 20px;
		}
	}

	i {
		font-size: 60px;
		margin-bottom: 15px;
		display: block;
	}
}

.social-share {
	display: inline-flex;
	flex-wrap: wrap;
	gap: 8px;
	padding-top: 10px;

	a {
		opacity: 0.3;
		transition: opacity 0.5s;

		&:hover {
			opacity: 0.5;
		}

		svg {
			width: 20px;
			height: 20px;
		}
	}
}
