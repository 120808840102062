.services {
	position: relative;
	z-index: 3;

	@include full-width;

	.artifact-grid-vertical {
		top: 150px;
		left: -20px;

		@include phone {
			left: -60px;
		}
	}

	.page-title {
		padding: 80px 0;
		max-width: 850px;
		margin-bottom: 0;
		position: relative;
		z-index: 4;

		@include phone {
			padding: 50px 0;
		}

		h1 {
			margin: 0;
		}

		p {
			margin: 30px 0;
		}

		.btn {
			margin-bottom: 35px;
		}

		.experts {
			a {
				color: $text-gray;
			}

			img {
				margin-bottom: 8px;
			}

			span {
				displaY: block;
				font-size: 14px;
				font-weight: 500;
			}
		}
	}

	.section-title {
		margin-bottom: 50px;

		h2 {
			font-size: 34px;
		}
	}

	.highlights {
		margin-bottom: 80px;
		position: relative;

		@include phone {
			margin-bottom: 50px;
		}

		.artifact-circle-yellow {
			bottom: -20px;
			right: -80px;

			@include phone {
				display: none;
			}
		}

		.site-container {
			z-index: 3;
			position: relative;
			border-top: 1px solid rgba(51,71,89,0.12);
			padding-top: 60px;

			@include phone {
				padding: 50px 0 0 0;
			}
		}

		.columns {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: 60px;

			@include phone {
				margin-bottom: 40px;
			}

			.column {
				flex: 0 0 32%;
				padding: 30px;
				border: 1px solid rgba(33,52,76,0.06);
				box-shadow: 0 1px 25px 1px rgba(0,0,0,0.06);
				border-radius: 6px;
				background: #fff;

				@include phone {
					flex: 0 0 100%;
					margin-bottom: 20px;
				}

				svg {
					margin-bottom: 15px;
				}

				h3 {
					font-size: 22px;
					font-weight: 600;
					margin-bottom: 15px;
				}

				p {
					font-size: 16px;
					line-height: 30px;
					margin: 0;
					color: $base-navy;
				}
			}
		}

		blockquote {
			text-align: center;
			max-width: 740px;
			margin: 0 auto;

			p {
				font-size: 25px;
				line-height: 40px;
				color: $base-navy;

				@include phone {
					font-size: 22px;
					line-height: 36px;
				}
			}
		}
	}

	.latest-work {
		background: $base-light-navy;
		padding: 60px 0 0 0;
		border-top: 1px solid #f4f4f4;
		border-bottom: 1px solid #f4f4f4;
		margin-bottom: 60px;
		position: relative;
		z-index: 3;

		@include full-width;

		@include phone {
			padding: 40px 0 0 0;
			margin-bottom: 40px;
		}

		.columns {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			figure {
				margin: 0 0 60px 0;
				padding: 0;
				text-align: center;

				@include phone {
					margin-bottom: 30px;
				}

				img {
					border: 1px solid #E9E9E9;
					border-radius: 4px;
					margin-bottom: 15px;

					@include phone {
						width: 100%;
						height: auto;
					}
				}

				figcaption {
					font-size: 14px;
					font-weight: 600;
				}
			}
		}
	}

	.plans {
		position: relative;

		&.site-container {
			padding: 0;
		}

		.plans-table {
			border: 1px solid #DFDFDF;
			border-bottom: 0;
			border-radius: 4px 4px 0 0;

			.columns {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.column {
					padding: 35px;
					border-right: 1px solid #dfdfdf;
					flex: 0 0 33%;

					@include phone {
						padding: 25px;
						flex: 0 0 100%;
						border: none;
						border-bottom: 1px solid #dfdfdf;
					}

					&:last-child {
						border-right: none;
					}

					h3 {
						font-size: 28px;
						margin: 0 0 15px 0;
						font-weight: 500;
					}

					h4 {
						font-size: 17px;
						margin: 0 0 15px 0;
					}

					.price {
						font-size: 26px;
						margin: 0 0 20px 0;
						font-family: $font-primary;
						display: block;

						sub {
							bottom: 0;
							top: -4px;
							font-size: 16px;
						}
					}

					hr {
						border: none;
						background: #DFDFDF;
						height: 1px;
						width: 100%;
						margin: 30px 0;
					}

					b {
						display: block;
						margin-bottom: 15px;
						font-size: 17px;
					}

					ul {
						padding-left: 20px;
						margin: 0;
						line-height: 34px;
					}
				}
			}
		}

		.custom {
			background: $base-navy;
			padding: 45px;
			border-radius: 0 0 4px 4px;
			text-align: center;
			margin-bottom: 60px;

			@include phone {
				padding: 30px;
				margin-bottom: 40px;
			}

			h3 {
				font-size: 28px;
				margin: 0 0 15px 0;
				font-weight: 500;
				color: #fff;
			}

			p {
				margin: 0 0 15px 0;
			}

			.price {
				display: block;
				font-size: 26px;
				color: #fff;
				font-family: $font-primary;
				margin-bottom: 25px;
			}
		}

		.book-call {
			margin-bottom: 60px;

			p {
				margin: 20px 0;
			}
		}

		.artifact-circle-blue {
			left: -300px;
			bottom: -260px;
		}
	}

	.faqs {
		position: relative;
		z-index: 3;

		@include phone {
			padding-bottom: 40px;
		}
	}
}
