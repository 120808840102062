.featured-image {
	margin-bottom: 50px;

	@include phone {
		margin-bottom: 25px;
	}

	img {
		border: 1px solid rgba(51,71,89,0.12);
		box-shadow: 0 0 18px 0 rgba(0,0,0,0.03);
		border-radius: 6px;
		object-fit: cover;
		object-position: top center;
		width: 100%;
		height: auto;
	}
}

.post-content-container {
	display: flex;
	justify-content: space-between;

	@include phone {
		flex-wrap: wrap;
	}

	aside {
		flex: 0 0 25%;

		@include phone {
			flex: 0 0 100%;
			border-bottom: 1px solid #eaeaea;
			margin-bottom: 25px;
			padding-bottom: 10px;
		}

		.inner {
			position: -webkit-sticky;
			position: sticky;
			top: 25px;
		}

		strong {
			font-size: 17px;
			font-weight: 600;
			margin-bottom: 10px;
			display: block;
			margin-bottom: 20px;
		}

		.social-share {
			@include phone {
				display: none;
			}
		}
	}
}

.post-content {
	&.with-sidebar {
		width: 70%;

		@include phone {
			width: 100%;
		}
	}

	&.with-preview {
		padding: 30px;
	}

	h2,
	h3,
	p,
	ul,
	ol,
	figure,
	.hint-box {
		a {
			color: $base-navy;

			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 1.5px;
				border-radius: 5px;
				background-color: $base-navy;
				bottom: -2px;
				left: 0;
				transform-origin: right;
				transform: scaleX(1);
				transition: transform .3s ease-in-out;
			}

			&:hover::before {
				transform-origin: left;
				transform: scaleX(0);
			}
		}
	}

	h2 {
		font-size: 34px;
		font-weight: 500;
		margin-bottom: 30px;

		@include phone {
			font-size: 32px;
			line-height: 44px;
		}
	}

	h3 {
		font-size: 26px;
		font-weight: 500;
		margin-bottom: 30px;
	}

	h4 {
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 30px;
	}

	p,
	ul,
	ol {
		color: $base-navy;
		font-size: 17px;
		line-height: 34px;
		margin-top: 0;
		margin-bottom: 30px;
	}

	ul,
	ol {
		padding-left: 20px;
		margin-left: 0;
	}

	pre {
		padding: 20px;
		border-radius: 4px;
		line-height: 23px;
		margin-bottom: 30px;
		color: #d0d0d0;
		overflow: scroll;
	}

	p code,
	ul code,
	ol code {
		vertical-align: 2px;
		word-break: break-all;
		padding: 3px 5px 5px;
		font-size: .8em;
		line-height: 1em;
		font-weight: 400!important;
		background: #e5eff5;
		border-radius: 3px;
	}

	img {
		max-width: 100%;
		height: auto;
		display: block;
		margin-bottom: 30px;
		border: 1px solid #eaeaea;
		border-radius: 5px;
	}

	blockquote {
		margin: 0 0 30px 0;
		padding: 0 1.5em 0 1.5em;
		border-left: 3px solid $accent-yellow;
		font-style: italic;
	}

	figure {
		padding: 0;
		margin: 0 0 30px 0;
		display: table;
		margin-right: auto;
		margin-left: auto;

		img {
			margin-bottom: 15px;
		}

		figcaption {
			color: #5d7179;
			font-size: 15px;
			line-height: 1.5em;
			text-align: center;
		}

		&.highlight {
			width: 100%;

			pre {
				margin: 0;
			}
		}
	}

	hr {
		background: #e9e9e9;
		height: 1px;
		width: 100%;
		border: 0;
		margin-bottom: 30px;
	}

	.lead-magnet {
		background: #fff;
		border: 1px solid rgba(33,52,76,0.06);
		box-shadow: 0 1px 25px 1px rgba(0,0,0,0.06);
		border-radius: 6px;
		margin-bottom: 30px;

		img {
			border-radius: 5px 5px 0 0;
			margin-bottom: 0;
		}

		form {
			padding: 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include phone {
				padding: 20px;
			}

			p {
				margin-bottom: 0;
			}

			p:first-child {
				flex: 1;
				margin-right: 15px;
			}
		}
	}

	table {
		display: inline-block;
		overflow-x: auto;
		margin: .5em 0 2.5em;
		max-width: 100%;
		width: auto;
		border-spacing: 0;
		border-collapse: collapse;
		font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
		font-size: 15px;
		white-space: nowrap;
		vertical-align: top;
		-webkit-overflow-scrolling: touch;
		background: radial-gradient(ellipse at left,rgba(0,0,0,.2) 0,transparent 75%) 0,radial-gradient(ellipse at right,rgba(0,0,0,.2) 0,transparent 75%) 100%;
		background-attachment: scroll,scroll;
		background-size: 10px 100%,10px 100%;
		background-repeat: no-repeat;

		td:first-child {
			background-image: linear-gradient(90deg,#fff 50%,hsla(0,0%,100%,0));
			background-size: 20px 100%;
			background-repeat: no-repeat;
		}

		td:last-child {
			background-image: linear-gradient(270deg,#fff 50%,hsla(0,0%,100%,0));
			background-position: 100% 0;
			background-size: 20px 100%;
			background-repeat: no-repeat;
		}

		th {
			color: #15171a;
			font-size: 15px;
			font-weight: 700;
			text-align: left;
			text-transform: uppercase;
			background-color: #f4f8fb;
		}

		td,
		th {
			padding: 14px 12px;
			border: 1px solid #e3ecf3;
		}
	}

	.hint-box {
		border: none;
		font-style: normal;
		background: lighten($base-navy, 75%);
		padding: 25px;
		border-radius: 4px;
		margin-bottom: 30px;
		line-height: 30px;

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	video {
		width: 100%;
		height: auto;
		margin-bottom: 40px;
		border-radius: 6px;
		border: 1px solid #eaeaea;
	}

	.author {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #eaeaea;
		padding-top: 40px;

		@include phone {
			align-items: flex-start;
		}

		img {
			max-width: 88px;
			border-radius: 4px;
			height: auto;
			margin: 0 25px 0 0;
		}

		h5 {
			font-size: 19px;
			font-weight: 700;
			margin-bottom: 10px;

			a {
				color: $accent-yellow;

				&::before {
					background-color: $accent-yellow;
				}
			}
		}

		p {
			font-size: 16px;
			line-height: 28px;
			margin: 0;
			color: lighten($text-gray, 5%);

			@include phone {
				font-size: 15px;
				line-height: 25px;
			}
		}
	}
}
