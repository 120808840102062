.artifact-circle-yellow,
.artifact-circle-blue {
	width: 378px;
	height: 378px;
	background-image: url("/assets/img/artifact-circle-yellow.svg");
	background-size: 378px 378px;
	position: absolute;
	z-index: 1;
}

.artifact-circle-blue {
	background-image: url("/assets/img/artifact-circle-blue.svg");
}

.artifact-grid {
	width: 317px;
	height: 103px;
	background-image: url("/assets/img/artifact-grid.svg");
	background-size: 317px 103px;
	position: absolute;
	z-index: 1;
}

.artifact-grid-vertical {
	width: 100px;
	height: 254px;
	background-image: url("/assets/img/artifact-grid-vertical.svg");
	background-size: 100px 254px;
	position: absolute;
	z-index: 1;
}

.artifact-stripe {
	width: calc(100vw + 100px);
	height: 300px;
	transform: rotate(-10deg);
	background: rgba(51,71,89,0.03);
	position: absolute;
	z-index: 1;
}
