footer {
	padding-top: 60px;
	position: relative;
	z-index: 4;

	@include phone {
		padding-top: 30px;
	}

	.footer-wrap {
		background: $base-navy;
		padding: 50px 0 20px 0;

		@include phone {
			padding: 30px;
		}
	}

	.columns {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		border-bottom: 1px solid #38475A;
		padding-bottom: 40px;
		margin-bottom: 30px;

		@include phone {
			padding-bottom: 30px;
		}

		.column {
			flex: 0 0 30%;

			@include phone {
				flex: 0 0 100%;
			}

			h4 {
				color: #fff;
				font-size: 20px;
				margin: 0 0 15px 0;
			}

			p {
				font-size: 15px;
				margin: 0;
				color: $text-gray;
				line-height: 24px;
			}

			&:first-child {
				flex: 0 0 24%;

				@include phone {
					flex: 0 0 100%;
					margin-bottom: 30px;
					padding-bottom: 30px;
					border-bottom: 1px solid #38475A;
				}

				.logo {
					border: none;
				}

				svg {
					width: 174px;
					height: auto;
					margin-bottom: 15px;
				}

				p {
					margin-bottom: 20px;
				}

				.twitter {
					font-family: $font-primary;
					font-size: 16px;
					font-weight: 600;
					background: url("/assets/img/icon-twitter.svg") no-repeat 0px 3px;
					padding-left: 30px;

					a {
						color: #fff;
					}
				}
			}

			&:nth-child(2) {
				@include phone {
					margin-bottom: 30px;
					padding-bottom: 30px;
					border-bottom: 1px solid #38475A;
				}

				ul {
					list-style: none;
					margin: 0;
					padding: 0;
					column-count: 2;

					li {
						line-height: 35px;

						a {
							color: #fff;
							font-size: 15px;
						}
					}
				}
			}

			&:nth-child(3) {
				p {
					margin-bottom: 20px;
				}

				form {
					display: flex;
					justify-content: flex-start;

					input[type="email"] {
						border-radius: 4px 0 0 4px;
						border: none;
						font-size: 15px;
						padding: 15px;
						width: 100%;
					}

					input[type="submit"] {
						border-radius: 0 4px 4px 0;
						border: none;
						padding: 15px;
						font-size: 14px;
						font-weight: 700;
						font-family: $font-primary;
						text-transform: uppercase;
						color: #fff;
						background: $accent-teal;
						cursor: pointer;
					}
				}
			}
		}
	}

	.columns-bottom {
		display: flex;
		justify-content: space-between;
		color: $text-gray;
		font-size: 14px;
		padding-bottom: 10px;

		@include phone {
			display: block;
			text-align: center;
		}

		.column:first-child {
			@include phone {
				margin-bottom: 15px;
			}
		}

		a {
			color: #fff;

			.twitter {
				font-weight: 600;
			}
		}

		span {
			margin: 0 5px;
		}
	}
}
