.legal {
	.policy.container {
		max-width: 800px;
		margin: 0 auto;
	}

	h2 {
		font-size: 28px;
	}

	p,
	ul {
		color: $text-gray;

		 &.last {
			 margin-bottom: 35px;
		 }

		 b {
			 color: darken($text-gray, 50%);
		 }
	}

	ul {
		margin: 0 0 30px 0;
		padding-left: 30px;
		line-height: 32px;
	}

	.text-link {
		color: $base-navy;

		&::before {
			background-color: $base-navy;
		}
	}

	.spacer {
		height: 20px;
	}

	&.thankyou {
		text-align: center;

		.page-title {
			margin-bottom: 40px;
			border-bottom: 1px solid #eaeaea;
			padding-bottom: 40px;
		}
	}
}
