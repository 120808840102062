.icon-star {
	background-image: url("/assets/img/icon-star.svg");
	background-size: 21px 21px;
	width: 21px;
	height: 21px;
	display: inline-block;
}

.icon-question {
	background-image: url("/assets/img/icon-question.svg");
	background-size: 21px 21px;
	width: 21px;
	height: 21px;
	display: inline-block;
}

.icon-link {
	background-image: url("/assets/img/icon-link.svg");
	background-size: 21px 21px;
	width: 21px;
	height: 21px;
	display: inline-block;
}

.icon-compatibility {
	background-image: url("/assets/img/icon-compatibility.svg");
	background-size: 19px 19px;
	width: 19px;
	height: 19px;
	display: inline-block;
}

.icon-version {
	background-image: url("/assets/img/icon-version.svg");
	background-size: 20px 20px;
	width: 20px;
	height: 20px;
	display: inline-block;
}

.icon-update {
	background-image: url("/assets/img/icon-update.svg");
	background-size: 20px 20px;
	width: 20px;
	height: 20px;
	display: inline-block;
}
.icon-documentation {
	background-image: url("/assets/img/icon-documentation.svg");
	background-size: 20px 20px;
	width: 20px;
	height: 20px;
	display: inline-block;
}

.icon-collection {
	background-image: url("/assets/img/icon-collection.svg");
	background-size: 25px 25px;
	width: 25px;
	height: 25px;
	display: inline-block;
}

.icon-book {
	background-image: url("/assets/img/icon-book.svg");
	background-size: 25px 25px;
	width: 25px;
	height: 25px;
	display: inline-block;
}

.icon-loading {
	background-image: url("/assets/img/loading.svg");
	background-size: 18px 18px;
	width: 18px;
	height: 18px;
	display: inline-block;
	vertical-align: -4px;
	margin-right: 5px;
}
