.faqs {
	text-align: center;
	margin-bottom: -60px;
	background: $base-light-navy;
	padding-top: 70px;

	@include full-width;

	@include phone {
		padding-top: 40px;
	}

	h2 {
		margin-bottom: 60px;
		text-align: left;

		@include phone {
			text-align: center;
			margin-bottom: 40px;
			font-size: 34px;
			line-height: 44px;
		}
	}

	.faq-grid {
		text-align: left;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		@include phone {
			display: block;
			padding: 0 20px;
		}
	}

	.faq {
		flex: 0 0 46%;
		margin-bottom: 40px;

		@include phone {
			padding-bottom: 0;
			margin-bottom: 0;
		}

		h4 {
			line-height: 32px;
			font-size: 22px;
			font-weight: 500;

			span {
				color: $accent-yellow;
				font-weight: 600;
			}
		}

		.content {
			@include phone {
				padding-bottom: 15px;
				margin-bottom: 40px;
				border-bottom: 2px solid #f1f1f1;
			}

			&:last-child {
				border: none;
				margin-bottom: 0;
			}
		}

		.content p {
			color: $text-gray;
			font-size: 16px;
			line-height: 30px;

			a {
				color: $base-navy;

				&::before {
					background-color: $base-navy;
				}
			}
		}
	}
}
