header {
	padding-top: 40px;
	position: relative;

	@include phone {
		padding-top: 30px;
	}

	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&.light {
		.logo {
			@include logo-light;
		}

		nav ul li a {
			color: $base-navy;

			&::before {
				background-color: $base-navy;
			}
		}

		.mobile-nav-toggle {
			span {
				background: $base-navy;
			}
		}
	}

	nav {
		@include phone {
			display: none;
		}

		ul {
			list-style: none;

			li {
				display: inline-block;
				margin-left: 30px;

				a {
					font-family: $font-primary;
					color: #fff;
					font-size: 16px;
					text-decoration: none;
					position: relative;
					cursor: pointer;

					&::before {
						content: '';
						position: absolute;
						width: 100%;
						height: 1.5px;
						border-radius: 5px;
						background-color: #fff;
						bottom: -2px;
						left: 0;
						transform-origin: right;
						transform: scaleX(0);
						transition: transform .3s ease-in-out;
					}

					&:hover::before {
						transform-origin: left;
						transform: scaleX(1);
					}
				}
			}
		}
	}
}

.mobile-nav-toggle {
	width: 24px;
	height: 24px;
	position: absolute;
	top: 44px;
	right: 5px;
	transform: rotate(0deg);
	transition: rotate .5s ease-in-out;
	cursor: pointer;
	display: none;
	z-index: 5000;

	@include phone {
		display: block;
	}

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: #fff;
		border-radius: 5px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 0px;
			width: 60% !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			top: 7px;
		}

		&:nth-child(4) {
			top: 14px;
			width: 80% !important;
		}
	}

	&.open {
		span {
			&:nth-child(1) {
				top: 10px;
				width: 0% !important;
				left: 50%;
			}

			&:nth-child(2) {
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				transform: rotate(-45deg);
			}

			&:nth-child(4) {
				top: 10px;
				width: 0% !important;
				left: 50%;
			}
		}
	}
}

body.menu-opened {
	height: 100vh;
	overflow: hidden;

	header {
		background: $base-navy;
		bottom: 0;
		left: 0;
		overflow-y: scroll;
		position: fixed;
		right: 0;
		top: 0px;
		z-index: 900;
		padding: 30px 20px;

		.inner {
			grid-gap: 2vmin;
			display: grid;
			grid-auto-flow: row dense;
			grid-template-columns: 1fr;
			grid-template-rows: auto 1fr auto;
			height: 100%;
			position: relative;
			z-index: 1000;
			width: 100%;
		}

		.mobile-nav-toggle {
			right: 25px;

			span {
				background: #fff;
			}
		}

		.logo {
			svg {
				#mark {
					stroke: #fff;
				}

				#text {
					fill: #fff;
				}
			}
		}

		nav {
			text-align: center;
			padding-top: 0;

			@include phone {
				display: block;
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					display: block;
					margin-bottom: 30px;
					margin-left: 0;

					a {
						color: #fff;
						font-size: 22px;
						font-weight: 600;
					}
				}
			}
		}
	}
}
