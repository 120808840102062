.hello-bar {
	background: $accent-green;
	padding: 18px 0;

	p {
		color: #fff;
		margin: 0;
		padding: 0;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		font-family: $font-primary;

		b {
			font-weight: 700;
		}

		span:first-of-type {
			display: inline;

			@include phone {
				display: none;
			}
		}

		span:last-of-type {
			@include phone {
				display: inline;
			}
		}

		span {
			display: none;
		}

		a {
			color: #fff;
		}
	}
}
