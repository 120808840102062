/* ========================================
Fonts
========================================= */
$font-primary: 'Nunito Sans', sans-serif;
$font-secondary: 'Inter', sans-serif;

/* ========================================
Breakpoints
========================================= */
$desktop-wide: 1200px;
$desktop-wider: 1400px;
$desktop-extended: 1600px;
$tablet-landscape: 1024px;
$tablet: 768px;
$phone: 576px;

/* ========================================
Colors
========================================= */
$base-navy: #21344C;
$base-gray: #D2D6DB;
$base-light-navy: #FBFBFC;
$accent-teal: #6AD9BF;
$accent-yellow: #B2CF31;
$accent-green: #31CF37;
$text-gray: #6E7C89;
